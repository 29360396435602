<template>
  <v-container fluid class="pa-0">
    <v-toolbar>
      <v-slide-group multiple show-arrows height="80">
        <v-slide-item class="mt-9 mr-2">
          <v-text-field label="Nom" v-model="filter_name"></v-text-field>
        </v-slide-item>
        <v-slide-item class="mt-9">
          <v-select label="Statut" :items="statut" item-value="value" item-text="label"
                    v-model="filter_state"></v-select>
        </v-slide-item>
        <v-slide-item class="mt-2">
          <div>
            <SiteTreeSelector v-model="site_tree"/>
          </div>
        </v-slide-item>
        <v-slide-item class="mt-8">
          <v-btn color="red" text @click="resetFilter"><v-icon>mdi-trash-can</v-icon> Retirer les filtres</v-btn>
        </v-slide-item>
      </v-slide-group>
    </v-toolbar>
    <v-navigation-drawer width="600" app temporary absolute right v-model="collabDrawer">
      <CollabAssignmentForm @close-assignment-drawer="collabDrawer = false"/>
    </v-navigation-drawer>
    <v-navigation-drawer width="600" app temporary absolute right v-model="prestataireDrawer">
      <PrestatairePropositionForm @close-prestataire-drawer="prestataireDrawer = false"/>
    </v-navigation-drawer>
    <v-row class="ma-0">
      <v-col order-md="1" order="2" cols="12" md="5" id="intervention-list">
        <InterventionList :interventions-loaded="interventions_loaded"/>
      </v-col>
      <v-col order-md="2" order="1" md="7" cols="12" id="intervention-view">
        <router-view @open-assignment-drawer="openAssignmentDrawer();"
                     @open-prestataire-drawer="prestataireDrawer = true"></router-view>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import InterventionList from "@/components/interventions/InterventionList";
import CollabAssignmentForm from "@/components/interventions/CollabAssignmentForm";
import PrestatairePropositionForm from "@/components/interventions/PrestatairePropositionDrawer";
import SiteTreeSelector from "@/views/SiteTreeSelector";
import {DrawerMixin} from "@/mixins/util/DrawerMixin";

let statut_select = [
  {"value": "", "label": "Interventions actives"},
  {"value": "waiting", "label": "En attente"},
  {"value": "assigned", "label": "Assigné"},
  {"value": "running", "label": "En cours"},
  {"value": "validation", "label": "A valider"},
  {"value": "to_note", "label": "A noter"},
  {"value": "no_cost", "label": "Sans coût affecté"},
  {"value": "finished", "label": "Réalisée"},
  {"value": "all", "label": "Toutes les interventions"}
]
export default {
  name: "Interventions",
  components: {
    PrestatairePropositionForm, InterventionList, CollabAssignmentForm,
    SiteTreeSelector
  },
  mixins: [DrawerMixin],
  async mounted() {
    let that = this;
    this.$store.dispatch("interventions/fetchInterventions")
        .then(() => that.loaded_intervention = true);
    this.handleQueryParameters();
    if (this.$store.getters["sites/allSites"].length === 0) {
      await this.$store.dispatch("sites/fetchSites");
    }
  },
  data() {
    return {
      "propositionDrawer": false,
      "collabDrawer": false,
      "prestataireDrawer": false,
      editDrawer: false,
      statut: statut_select,
      chosen_site: null,
      interventions_loaded: false,
    }
  },
  methods: {
    openAssignmentDrawer() {
      window.scrollTo(0, 0);
      this.collabDrawer = true;
    },
    handleQueryParameters() {
      let states = statut_select.map(statut => statut.value);
      let state = this.$route.query.state;
      if (states.indexOf(state) !== -1) this.$store.commit('interventions/setFilterState', state);
    },
    site_name(site) {
      let siteObject = site;
      if (siteObject === null) return "Non défini";
      let siteText = siteObject.name;
      if (siteObject.parent !== null) {
        siteText += `, ${siteObject.parent}`;
      }
      if (siteObject.top !== null) {
        siteText += `, ${siteObject.top}`;
      }
      return siteText;
    },
    setSelectedSite() {
      if (this.chosen_site === null) {
        this.selected_site = "";
        return;
      }
      let found_site = this.allSites.find(site => site.id === this.chosen_site);
      if (found_site !== undefined) {
        this.selected_site = found_site.fullname;
      }
    },
    resetFilter() {
      this.site_tree = [];
      this.filter_state = "";
      this.filter_name = "";
    }
  },
  computed: {
    filter_state: {
      get() {
        return this.$store.getters["interventions/getFilterState"];
      },
      set(value) {
        this.$store.commit("interventions/setFilterState", value);
      }
    },
    filter_name: {
      get() {
        return this.$store.getters["interventions/getFilterName"];
      },
      set(value) {
        this.$store.commit("interventions/setFilterName", value);
      }
    },
    site_tree: {
      get() {
        return this.$store.getters["interventions/getSiteTree"];
      },
      set(value) {
        this.$store.commit("interventions/setSiteTree", value);
      }
    },
    logged_user() {
      return this.$store.getters["users/getLoggedUser"];
    },
    sites_select() {
      return [
        {value: "", name: "Tous les sites"},
        ...this.$store.getters["sites/allSites"]
            .map(site => ({"name": this.site_name(site), "value": this.site_name(site)}))
      ];
    },
    allSites() {
      return this.$store.getters["sites/allSites"];
    },
    selected_site: {
      get() {
        return this.$store.getters["interventions/getFilterSite"];
      },
      set(value) {
        return this.$store.commit("interventions/setFilterSite", value)
      }
    },
    selected_intervention_id() {
      let intervention = this.$store.getters["interventions/getSelectedIntervention"];
      return intervention == null ? 0 : intervention.id;
    }
  }
}
</script>

